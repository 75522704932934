// @flow

import React, { useState, useEffect } from 'react'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import baseStyles from './ItbVideoStand.scss'

type Props = {
  currentPlace: number,
  currentDuration: number,
  ...StyleProps
}

const ProgressBarIndicator = ({ currentPlace, currentDuration, styles }: Props) => {
  const [ render, setRender ] = useState(true)

  useEffect(() => {
    setRender(false)
  }, [ currentDuration, currentPlace ])

  useEffect(() => {
    if (!render) {
      setRender(true)
    }
  }, [ render ])

  return (
    render ? <>
      <style>
        {`@keyframes videoAnimatePBWidth {
          0% {
            width: ${currentPlace}%;
          }
        
          100% {
            width: 100%;
          }
        }`}
      </style>
      <div
        className={styles.progressBarIndicator}
        style={{
          animationDuration: `${currentDuration}s`,
          animationIterationCount: 1,
          animationName: 'videoAnimatePBWidth',
          animationTimingFunction: 'linear'
        }}
      />
    </> : null
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ProgressBarIndicator)
