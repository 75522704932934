// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import ItbVideoStand from '../modules/ItbVideoStand'
import SEO from '../components/seo'

const ItbVideoStandPage = () => {
  const { locale } = useIntl()

  return (
    <>
      <SEO title="ITB2024" description="ITB2024" lang={locale} nofollow />
      <ItbVideoStand />
    </>
  )
}

export default ItbVideoStandPage
