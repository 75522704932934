import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

/* eslint-disable max-len */
const logoQS = (
  <StaticImage src="../../images/video-stand/QuickStart.png" alt="" objectFit="contain" className="image imageActive" />
)
const logoQSLight = (
  <StaticImage src="../../images/video-stand/QuickStart-light.png" alt="" objectFit="contain" className="image" />
)

const logoFS = (
  <StaticImage src="../../images/video-stand/Fusion.png" alt="" objectFit="contain" className="image imageActive" />
)
const logoFSLight = (
  <StaticImage src="../../images/video-stand/Fusion-light.png" alt="" objectFit="contain" className="image" />
)

const logoFM = (
  <StaticImage src="../../images/video-stand/freshMS.png" alt="" objectFit="contain" className="image imageActive" />
)
const logoFMLight = (
  <StaticImage src="../../images/video-stand/freshMS-light.png" alt="" objectFit="contain" className="image" />
)
/* eslint-enable max-len */

const QS_VIDEO_ID = 'IQJvQlYA0W8'
const FS_VIDEO_ID = 'l9qS0VKTYhc'
const FM_VIDEO_ID = 'fSpR6ZAc1OA'

export const VIDEO_LIST = [
  {
    id: QS_VIDEO_ID,
    type: 'yt',
    name: 'Your eCommerce Platform',
    imgActive: logoQS,
    img: logoQSLight
  },
  {
    id: FS_VIDEO_ID,
    type: 'yt',
    code: 'fusion',
    name: 'Product-Source Connector',
    imgActive: logoFS,
    img: logoFSLight
  },
  {
    id: FM_VIDEO_ID,
    type: 'yt',
    name: 'Marketing Management System',
    imgActive: logoFM,
    img: logoFMLight
  },
]

export const VIDEO_LIST_ORDER = [QS_VIDEO_ID, FS_VIDEO_ID, FM_VIDEO_ID]

export const PLAYER_OPTIONS = {
  height: '2160',
  width: '3840',
  playerVars: {
    controls: 1,
    fs: 0,
    autoplay: 0,
    mute: 1,
    enablejsapi: 1,
    rel: 0,
    disablekb: 1,
    modestbranding: 1,
    showinfo: 0
  }
}
