// @flow

import React, { useState, useEffect, useCallback } from 'react'
import Gallery from '@fcse/tsbob/dist/components/Gallery'
import YouTube from 'react-youtube'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { VIDEO_LIST, VIDEO_LIST_ORDER, PLAYER_OPTIONS } from './helpers'
import ProgressBarIndicator from './ProgressBarIndicator'
import baseStyles from './ItbVideoStand.scss'

type Props = {
  ...StyleProps
}

const ItbVideoStand = ({ styles }: Props) => {
  const [ videoData, setVideoData ] = useState({})
  const [ activeIdx, setActiveIdx ] = useState(0)
  const [ currentDuration, setCurrentDuration ] = useState(0)
  const [ currentPlace, setCurrentPlace ] = useState(0)

  useEffect(() => {
    if (Object.keys(videoData).length === VIDEO_LIST.length) {
      videoData[VIDEO_LIST_ORDER[0]].playVideo()
    }
  }, [videoData])

  const _onReady = event => {
    const videoId = event.target.getVideoData()['video_id']
    setVideoData(videoData => ({
      ...videoData,
      [videoId]: event.target
    }))
  }

  const _onEnd = event => {
    event.target.stopVideo()
    const newIdx = activeIdx + 1 >= VIDEO_LIST_ORDER.length ? 0 : activeIdx + 1
    setActiveIdx(newIdx)
    videoData[VIDEO_LIST_ORDER[newIdx]].playVideo()
  }

  const _onPlay = event => {
    const player = event.target
    setCurrentDuration(player.getDuration() - player.getCurrentTime())
    setCurrentPlace((player.getCurrentTime() / player.getDuration()) * 100)
    const videoId = player.getVideoData()['video_id']
    const currIdx = VIDEO_LIST_ORDER.findIndex(el => el === videoId)
    if (currIdx === activeIdx) return null
    videoData[VIDEO_LIST_ORDER[activeIdx]].stopVideo()
    setActiveIdx(currIdx)
  }

  const onThumbnailClick = idx => {
    if (idx === activeIdx) return null
    videoData[VIDEO_LIST_ORDER[activeIdx]].stopVideo()
    setActiveIdx(idx)
    videoData[VIDEO_LIST_ORDER[idx]].playVideo()
  }

  const renderItem = (item, key) => {
    return <div className={styles.videoItem} key={key}>
      <YouTube
        videoId={item.id}
        className={styles.video}
        containerClassName={styles.videoWrapper}
        opts={PLAYER_OPTIONS}
        onReady={_onReady}
        onPlay={_onPlay}
        onEnd={_onEnd}
      />
    </div>
  }

  const renderThumbnail = useCallback((item, key) => {
    return <div className={styles.galleryThumb} key={key}>
      <div className={styles.imageHolder}>
        {item.img}
        {item.imgActive}
      </div>
      <span className={styles.name}>{item.name}</span>
    </div>
  }, [styles.galleryThumb])

  return (
    <div className={styles.root}>
      <Gallery
        styles={styles.gallery}
        items={VIDEO_LIST}
        renderItem={renderItem}
        renderThumbnail={renderThumbnail}
        showNav={false}
        showThumbNav={false}
        updateCurrentIndexFromProps
        onThumbnailClick={onThumbnailClick}
        startIndex={activeIdx}
        lazyLoad={false}
      />
      <div className={styles.progressBar}>
        <ProgressBarIndicator
          currentDuration={currentDuration}
          currentPlace={currentPlace}
        />
      </div>
      <div className={styles.titleWrap}>
        <h1 className={styles.title}>
          Solutions to <br/><span>power your eCommerce.</span>
        </h1>
      </div>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ItbVideoStand)
